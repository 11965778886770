import fetchAPI from '../utils/fetch-api'
import {
	FETCH_POSTS_REQUEST,
	FETCH_POSTS_SUCCESS,
	FETCH_POSTS_FAILURE,
	UPDATE_POST_REQUEST,
	UPDATE_POST_SUCCESS,
	UPDATE_POST_FAILURE,
	FILTER_REVIEWED_POSTS,
} from '../constants/actionTypes'

import { closeModalDialog } from './layoutActions'

const BASE_URI = `${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}`

export const fetchPosts = function (filter = null) {
	return async dispatch => {
		let url = `${BASE_URI}/cpcs/all`
		if (filter) {
			filter = new URLSearchParams(filter).toString()
			url = `${url}?${filter}`
		}
		await fetchAPI(
			{
				url,
				types: [FETCH_POSTS_REQUEST, FETCH_POSTS_SUCCESS, FETCH_POSTS_FAILURE],
			},
			dispatch
		)
	}
}

export const updatePost = function (id, params) {
	return async dispatch => {
		const url = `${BASE_URI}/cpcs/update`
		const formData = new FormData()
		formData.append('id', id)
		formData.append('body', JSON.stringify(params))
		await fetchAPI(
			{
				url,
				types: [UPDATE_POST_REQUEST, UPDATE_POST_SUCCESS, UPDATE_POST_FAILURE],
				method: 'POST',
				body: formData,
			},
			dispatch
		)
	}
}

export const approvePost = function (id) {
	return async dispatch => {
		await updatePost(id, { is_reviewed: 1, published: 1 })(dispatch)
		dispatch({ type: FILTER_REVIEWED_POSTS })
	}
}

export const hidePost = function (id) {
	return async dispatch => {
		await updatePost(id, { is_reviewed: 1, published: 0 })(dispatch)
		dispatch({ type: FILTER_REVIEWED_POSTS })
	}
}

export const editPost = function (id, params) {
	return async dispatch => {
		await updatePost(id, params)(dispatch)
		dispatch(closeModalDialog())
	}
}
