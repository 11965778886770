import { connect } from 'react-redux'
import { openModalDialog, closeModalDialog } from '../../actions/layoutActions'
import ModalDialog from './ModalDialog'

export const mapStateToProps = ({ layout }) => {
	return {
		showModal: layout.showModal,
		title: layout.modalInfo.title,
		componentName: layout.modalInfo.componentName,
		additionalProps: layout.modalInfo.additionalProps,
	}
}

export const mapDispatchToProps = { openModalDialog, closeModalDialog }

export default connect(mapStateToProps, mapDispatchToProps)(ModalDialog)
