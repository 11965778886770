import React from 'react'
import { Link } from 'gatsby'
import { NavLink, Col, Row } from 'reactstrap'

import './footer.scss'

import CarePostcardLogo from '../../assets/logos/logo-carepostcard-dark.svg'
import Twitter from '../../assets/icons/icon-twitter-dark.svg'
import LinkedIn from '../../assets/icons/icon-linkedin-dark.svg'
import Facebook from '../../assets/icons/icon-facebook-dark.svg'
import Instagram from '../../assets/icons/icon-instagram-dark.svg'

const Footer = () => {
	return (
		<footer className='footer pt-3 mt-5'>
			<Row>
				<Col></Col>
				<Col lg={2} className='text-center pt-3'>
					<Link to='/' className=''>
						<img src={CarePostcardLogo} alt='CPC Logo' className='footer-logo' />
					</Link>
				</Col>
				<Col lg={6} className='d-flex footer-links justify-content-center pt-2'>
					{/* <NavLink href='/'>Home</NavLink> */}
					<NavLink href='/about'>About</NavLink>
					<NavLink href='mailto: info@wambi.org'>Contact</NavLink>
					<NavLink href='/terms'>Terms of Service</NavLink>
				</Col>
				<Col lg={2} className='footer-copyright text-center pt-3'>
					Copyright 2021 Wambi LLC
				</Col>
				<Col></Col>
			</Row>
			<Row className='text-center justify-content-center pt-1'>
				<Col lg={3}></Col>
				<Col lg={6}>
					<a href='https://twitter.com/carepostcard' target='_blank' rel='noopener noreferrer'>
						<img src={Twitter} alt='' className='footer-icon' />
					</a>
					<a href='https://www.facebook.com/carepostcardmovement' target='_blank' rel='noopener noreferrer'>
						<img src={Facebook} alt='' className='footer-icon' />
					</a>{' '}
					<a href='https://www.linkedin.com/company/carepostcard/' target='_blank' rel='noopener noreferrer'>
						<img src={LinkedIn} alt='' className='footer-icon' />
					</a>{' '}
					<a href='https://www.instagram.com/carepostcard/' target='_blank' rel='noopener noreferrer'>
						<img src={Instagram} alt='' className='footer-icon' />
					</a>{' '}
				</Col>
				<Col lg={3}></Col>
			</Row>
		</footer>
	)
}

export default Footer

//©
