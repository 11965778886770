import { connect } from 'react-redux'

import EditPost from './EditPost'
import { editPost } from '../../../actions/postsActions'

export const mapStateToProps = ({ posts }, { postId }) => {
	const post = posts.posts.find(post => post.id === postId)
	return {
		post,
	}
}

export const mapDispatchToProps = { editPost }

export default connect(mapStateToProps, mapDispatchToProps)(EditPost)
