/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.min.css'

function SEO({ description, lang, meta, title, metaImage, pathname }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
		`
	)

	const metaDescription = description || site.siteMetadata.description
	// const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: 'og:title',
					content: title,
				},
				{
					property: 'og:description',
					content: metaDescription,
				},
				{
					property: 'og:type',
					content: `website`,
				},
				{
					name: 'twitter:card',
					content: `summary`,
				},
				{
					name: 'twitter:creator',
					content: site.siteMetadata.author,
				},
				{
					name: 'author',
					content: site.siteMetadata.author,
				},
				{
					name: 'twitter:title',
					content: title,
				},
				{
					name: 'twitter:description',
					content: metaDescription,
				},
				{
					property: 'og:url',
					content: pathname,
				},
				{
					// name: "image",
					property: 'og:image',
					content:
						metaImage ||
						'https://carepostcard-env-development-media.s3.amazonaws.com/images/org-logos/Carepostcard-Logo-2020-darktext.png',
				},
				{
					name: 'google-site-verification',
					content: 'jBDl6RCGfpuZDp0g4nJnuEv0Bi9HJTLJJI1uK-i8cQ0',
				},
			]
				.concat(
					metaImage
						? [
								{
									property: 'og:image',
									content: metaImage,
								},
								{
									property: 'og:image:width',
									content: metaImage.width,
								},
								{
									property: 'og:image:height',
									content: metaImage.height,
								},
								{
									name: 'twitter:card',
									content: 'summary_large_image',
								},
						  ]
						: [
								{
									name: 'twitter:card',
									content: 'summary',
								},
						  ]
				)
				.concat(meta)}
		/>
	)
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
	pathname: PropTypes.string,
}

export default SEO
