import React from 'react'
import { Button, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

import { editHashtag } from '../../actions/hashtagActions'

const EditHashtag = ({ closeModalDialog, hashtagId }) => {
	const dispatch = useDispatch()
	const { register, handleSubmit, errors } = useForm()
	const hashtag = useSelector(state => state.hashtags.hashtags.find(tag => tag.id === hashtagId))
	const onSubmit = data => dispatch(editHashtag(hashtagId, data))

	const handleCloseModal = () => {
		dispatch(closeModalDialog())
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<ModalBody>
				<FormGroup row={true} className='text-left px-4'>
					<Input
						type='textarea'
						name='desc'
						id='desc'
						className='hashtag-description-field'
						innerRef={register({ required: true })}
						placeholder='Place hashtag info'
						defaultValue={hashtag && hashtag.desc ? hashtag.desc : ''}
					/>
				</FormGroup>
			</ModalBody>
			<ModalFooter>
				<Button color='primary'>Save</Button>
				<Button color='secondary' onClick={handleCloseModal}>
					Cancel
				</Button>
			</ModalFooter>
		</form>
	)
}

export default EditHashtag
