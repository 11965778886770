import React from 'react'
import { Modal, ModalHeader } from 'reactstrap'

import EditPost from '../CareCard/EditPost'
import EditHashtag from '../Hashtags/EditHashtag'

const components = { EditPost, EditHashtag }

const ModalDialog = ({ openModalDialog, showModal, componentName, title, closeModalDialog, additionalProps }) => {
	if (!componentName) return null
	const Component = components[componentName]
	return(
		<Modal isOpen={showModal} toggle={openModalDialog}>
			<ModalHeader toggle={closeModalDialog}>{title}</ModalHeader>
			<Component closeModalDialog={closeModalDialog} {...additionalProps} />
		</Modal>
	)
}

export default ModalDialog
