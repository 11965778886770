import React from 'react'

import './profileLogo.scss'

const ProfileLogo = props => {
	return (
		<div>
			<div className={`profile-logo-background-${props.size}`}>
				<span>{props.letter}</span>
			</div>
		</div>
	)
}

export default ProfileLogo
