export default function fetchAPI(options, dispatch) {
	const { types, url, payload = {}, headers = {}, method, body } = options

	const defaultHeaders = {
		// Accept: "application/vnd.api+json",
		// "Content-Type": "application/json",
	}

	const authenticationHeader = {}

	const hasValidActionTypes = !Array.isArray(types) || types.length !== 3 || !types.every(type => typeof type === 'string')

	if (hasValidActionTypes) {
		throw new Error('Expected an array of three string types.')
	}

	const [requestType, successType, failureType] = types

	function handleResponse(response) {
		if (response.status >= 200 && response.status < 300) {
			return response
		}
		return response.json().then(payload => {
			return Promise.reject(payload)
		})
	}

	dispatch({
		type: requestType,
		payload,
	})

	function formatPayload(response) {
		if (response.status === 204) return ''
		return response.json()
	}

	return fetch(url, {
		headers: { ...defaultHeaders, ...headers, ...authenticationHeader },
		method,
		body,
	})
		.then(response => handleResponse(response))
		.then(formatPayload)
		.then(payload => {
			dispatch({
				type: successType,
				payload,
			})
			return payload
		})
		.catch(error => {
			dispatch({
				error: error, //handle this error
				type: failureType,
			})
		})
}
