import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import './layout.scss'
import Header from './Header/header'
import Footer from './Footer/footer'
import WambiLogo from '../assets/logos/logo-wambi-purple.svg'
import ModalDialog from './ModalDialog'

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)

	return (
		<>
			<Header siteTitle={data.site.siteMetadata.title} pageTitle={children[0].props.title} />
			<div>{children}</div>
			<a href='https://www.wambi.org' target='_blank' rel='noopener noreferrer'>
				<img src={WambiLogo} alt='Wambi Logo' className='wambi-logo' />
			</a>
			<Footer />
			<ModalDialog />
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export const mapStateToProps = ({ layout }) => {
	return {
		showModal: layout.showModal,
	}
}

export default connect(mapStateToProps)(Layout)
