import React from 'react'
import { Button, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap'
import { useForm } from 'react-hook-form'

const EditPost = ({ closeModalDialog, post, editPost }) => {
	const { register, handleSubmit, errors } = useForm()
	const onSubmit = data => editPost(post.id, data)

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<ModalBody>
				<FormGroup row={true} className='text-left px-4'>
					<Input
						type='textarea'
						name='body'
						id='body'
						className='create-card-textarea'
						innerRef={register({ required: true })}
						placeholder='Tell the world about the moment that impacted you! Your Carepostcard can shine a light on a hospital&#39;s actions, a nurse&#39;s smile, or any story that showcases your gratitude for healthcare workers.'
						defaultValue={post.body}
					/>
				</FormGroup>
			</ModalBody>
			<ModalFooter>
				<Button color='primary'>Save</Button>
				<Button color='secondary' onClick={closeModalDialog}>
					Cancel
				</Button>
			</ModalFooter>
		</form>
	)
}

export default EditPost
