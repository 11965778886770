import React, { useEffect, useState } from 'react'
import { Col, DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown, Button, Row, Input, InputGroup } from 'reactstrap'
import { navigate, Link } from 'gatsby'
import axios from 'axios'

import './header.scss'

import ProfileLogo from '../ProfileLogo/profileLogo'
import CareLogo from '../../assets/logos/logo-carepostcard-dark.svg'
// import { compose } from 'redux'

const Header = () => {
	const [userId, setUserId] = useState(0)
	const [isModerator, setIsModerator] = useState(false)
	const [letter, setLetter] = useState('')
	const [loaded, setLoaded] = useState(false)
	const [searchResults, setSearchResults] = useState([])
	// const [searchModal, setSearchModal] = useState(false)

	useEffect(() => {
		async function getProfileData() {
			try {
				const userId = window.localStorage.getItem('userId')
				if (userId) {
					const response = await axios.get(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/users/id/${userId}`)
					setUserId(userId)
					setIsModerator(!!response.data.user.is_moderator)
					setLetter(response.data.user.name.charAt(0))
				}
				setLoaded(true)
			} catch (error) {
				console.error('Error getting profile data: ', error)
			}
		}
		getProfileData()
		getFeaturedOrgs()
	}, [])

	function logout() {
		window.localStorage.removeItem('userId')
		if (window.location.pathname === '/') {
			window.location.reload(true)
		} else {
			navigate('/')
		}
	}

	const handleSearch = async e => {
		if (e.target.value.length === 0) {
			await getFeaturedOrgs()
		} else if (e.target.value.length === 1 && e.target.value.charAt(0) === '#') {
			await getFeaturedTags()
		} else {
			try {
				if (e.target.value.charAt(0) === '#') {
					const { data } = await axios.get(
						`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/tags/search/${e.target.value.slice(1)}`
					)
					setSearchResults(data.results)
				} else {
					const { data } = await axios.get(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/orgs/search/${e.target.value}`)
					setSearchResults(data.results)
				}
			} catch (error) {
				console.error('Error retrieving orgs for location search: ', error)
			}
		}
	}

	async function getFeaturedOrgs() {
		try {
			const { data } = await axios.get(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/orgs/featured`)
			setSearchResults(data.orgs)
		} catch (error) {
			console.error('Error retrieving featured orgs: ', error)
		}
	}

	async function getFeaturedTags() {
		try {
			const { data } = await axios.get(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/tags/featured`)
			setSearchResults(data.tags)
		} catch (error) {
			console.error('Error retrieving featured orgs: ', error)
		}
	}

	const formatToTitleCase = str => {
		if (str) {
			str = str.toLowerCase()
			str = str.split(' ')

			for (var i = 0; i < str.length; i++) {
				str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
			}

			return str.join(' ')
		}
	}

	return (
		<header className='header mb-5'>
			<Row className=''>
				<Col md={4} className='text-center'>
					<Link to='/' className=''>
						<img src={CareLogo} alt='CPC Logo' className='header-logo' />
					</Link>
				</Col>
				<Col md={userId ? 4 : 3}>
					<UncontrolledDropdown>
						<DropdownToggle className='input-group'>
							<Input
								type='text'
								placeholder='Start typing or enter # for a hashtag...'
								className='input-text'
								onChange={handleSearch}
								// onFocus={() => setSearchModal(true)}
							/>
						</DropdownToggle>
						{/* {searchModal && ( */}
						<DropdownMenu className='search-results-view'>
							{searchResults.length ? (
								searchResults.map((_, index) => {
									return (
										<div key={index}>
											<DropdownItem
												href={
													searchResults[0].hashtag
														? `/hashtag/?hashtag=${encodeURIComponent(searchResults[index].hashtag)}`
														: `/org/?id=${searchResults[index].id}`
												}
												className='header-dropdown-button py-2'
											>
												{searchResults[0].hashtag ? (
													<Row className='search-list-title px-1'>{_.hashtag}</Row>
												) : (
													<>
														<Row className='search-list-title px-1'>{formatToTitleCase(_.name)}</Row>
														<Row className='search-list-subtitle px-1'>{`${formatToTitleCase(_.city)}, ${_.state}`}</Row>
													</>
												)}
											</DropdownItem>
											{index !== searchResults.length - 1 && <DropdownItem divider className='mx-3' />}
										</div>
									)
								})
							) : (
								<DropdownItem className='create-card-location-title px-3 py-3'>No results found.</DropdownItem>
							)}
						</DropdownMenu>
						{/* )} */}
					</UncontrolledDropdown>
				</Col>
				{loaded && (
					<>
						{userId ? (
							<>
								<Col md={3}>
									<UncontrolledDropdown className='d-flex profile-icon-wrapper'>
										<DropdownToggle className='mt-2 pl-4'>
											<ProfileLogo size={'small'} letter={letter} />
										</DropdownToggle>
										<DropdownMenu right>
											{isModerator && (
												<>
													<Link to='/admin' tabIndex='0' role='menuitem' className='header-dropdown-button dropdown-item'>
														Admin Panel
													</Link>
													<DropdownItem divider className='mx-4' />
													<Link
														to='/admin/manage-hashtags'
														tabIndex='0'
														role='menuitem'
														className='header-dropdown-button dropdown-item'
													>
														Manage Hashtags
													</Link>
													<DropdownItem divider className='mx-4' />
												</>
											)}
											<Link to='/profile' tabIndex='0' role='menuitem' className='header-dropdown-button dropdown-item'>
												My Profile
											</Link>
											<DropdownItem divider className='mx-4' />
											<DropdownItem className='header-dropdown-button' onClick={() => logout()}>
												Logout
											</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
								</Col>
								<Col md={1}></Col>
							</>
						) : (
							<Col md={5} className='d-flex header-buttons'>
								<Button id='header-join-now' href='/login/register' className='header-button-register'>
									Join now
								</Button>
								<Button id='header-sign-in' href='/login' className='header-button-login'>
									Sign in
								</Button>
							</Col>
						)}
					</>
				)}
			</Row>
		</header>
	)
}

export default Header
