import fetchAPI from '../utils/fetch-api'
import {
	FETCH_HASHTAGS_REQUEST,
	FETCH_HASHTAGS_SUCCESS,
	FETCH_HASHTAGS_FAILURE,
	SORT_HASHTAGS,
	UPDATE_HASHTAG_REQUEST,
	UPDATE_HASHTAG_SUCCESS,
	UPDATE_HASHTAG_FAILURE,
} from '../constants/actionTypes'
import { closeModalDialog } from './layoutActions'

const BASE_URI = `${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}`

export const fetchHashtags = () => {
	return async dispatch => {
		await fetchAPI(
			{
				url: `${BASE_URI}/tags/all`,
				types: [FETCH_HASHTAGS_REQUEST, FETCH_HASHTAGS_SUCCESS, FETCH_HASHTAGS_FAILURE],
			},
			dispatch
		)
	}
}

export const fetchFeaturedHashtags = () => {
	return async dispatch => {
		await fetchAPI(
			{
				url: `${BASE_URI}/tags/featured`,
				types: [FETCH_HASHTAGS_REQUEST, FETCH_HASHTAGS_SUCCESS, FETCH_HASHTAGS_FAILURE],
			},
			dispatch
		)
	}
}

export const sortHashtags = (oldIndex, newIndex) => {
	return { type: SORT_HASHTAGS, payload: { oldIndex, newIndex } }
}

export const updateHashtag = (id, params) => {
	return async dispatch => {
		const url = `${BASE_URI}/tags/update`
		const formData = new FormData()
		formData.append('id', id)
		formData.append('body', JSON.stringify(params))
		await fetchAPI(
			{
				url,
				types: [UPDATE_HASHTAG_REQUEST, UPDATE_HASHTAG_SUCCESS, UPDATE_HASHTAG_FAILURE],
				method: 'POST',
				body: formData,
			},
			dispatch
		)
	}
}

export const editHashtag = function (id, params) {
	return async dispatch => {
		await updateHashtag( id, params)(dispatch)
		dispatch(closeModalDialog())
	}
}
